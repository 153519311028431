#test-main {
  padding: 1rem;
  display: grid;
  grid-template-rows: 60px auto;
  grid-template-columns: 175px auto;
}

#test-header {
  grid-column: span 2;
  grid-row: span 1;
}

#test-menu {
  grid-column: span 1;
  grid-row: span 1;
}

.test-menu-item {
  cursor: pointer;
}

.test-menu-item:hover {
  background: #dddddd;
}

#test-body {
  background: #f5faff;
  padding: 1rem;
  grid-column: span 1;
  grid-row: span 1;
}
