@font-face {
  font-family: FranklinGothic URW;
  src: url('../type/FranklinGothicURW/FranklinGothicURW-Boo.otf') format('opentype')
    url('../type/FranklinGothicURW/FranklinGothicURW-BooIta.otf') format('opentype')
    url('../type/FranklinGothicURW/FranklinGothicURW-Dem.otf') format('opentype')
    url('../type/FranklinGothicURW/FranklinGothicURW-DemIta.otf') format('opentype')
    url('../type/FranklinGothicURW/FranklinGothicURW-Lig.otf') format('opentype')
    url('../type/FranklinGothicURW/FranklinGothicURW-Med.otf') format('opentype')
    url('../type/FranklinGothicURW/FranklinGothicURW-MedIta.otf') format('opentype');
}

:root {
  --color-fresh-red: #fa405c;
  --color-foreground-color: #062942;
  --color-white: #fbfdfe;
  --color-fresh-yellow: #edc02b;
  --color-freshbooks-light-grey: #cdd4d9;
  --color-fresh-grey: #8495a2;
  --color-freshbooks-green: #85c525;
  --color-freshbooks-blue: #0d83dd;
  --color-background-color: #ffffff;
  --color-fresh-green: #85c525;
  --color-fresh-blue: #0d83dd;
  --color-fresh-yellow: #edc02b;
  --color-fresh-red: #fa405c;
  --color-fresh-grey: #8495a2;
  --color-foreground-color: #062942;
  --color-background-color: #fbfdfe;
  --color-variant-light-grey: #abb7c0;

  --dialogue-lg-height: 750px;
  --dialogue-lg-width: 1180px;

  --dialogue-md-height: 350px;
  --dialogue-md-width: 550px;

  --dialogue-sm-height: 250px;
  --dialogue-sm-width: 350px;

  --text-weight-light: 300;
  --text-weight-x-light: 200;
  --text-weight-xx-light: 100;

  --fb-border-radius: 8px;
  --fb-border-width: 2px;
  --fb-border-color: #cdd4d9;
  --fb-shadow-offset: 2px;
  --fb-shadow-color: rgba(6, 41, 66, 0.1);
}

/* Setup stuff */

html {
  background: rgb(245, 245, 245);
  height: 100%;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
body {
  margin: auto;
  background: #fbfdfe;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

/* Palette */

.variant-blue {
  color: var(--color-freshbooks-blue);
}

.bg-blue {
  background: var(--color-freshbooks-blue);
}

.variant-green {
  color: var(--color-freshbooks-green);
}

.bg-green {
  background: var(--color-freshbooks-green);
}

.variant-light-grey {
  color: var(--color-variant-light-grey);
}

.variant-grey {
  color: var(--color-fresh-grey);
}

.bg-variant-white {
  background: #ffffff !important;
}

/* Typography */

.link {
  color: #0d83dd !important;
  font-weight: 300 !important;
  cursor: pointer;
}

.light {
  font-weight: var(--text-weight-light);
}

.x-light {
  font-weight: var(--text-weight-x-light);
}

.xx-light {
  font-weight: var(--text-weight-xx-light);
}

.fb-copy {
  color: var(--color-fresh-grey);
  font-weight: 200;
  letter-spacing: 0.3px;
}

.fb-subtext {
  color: var(--color-fresh-grey);
  font-size: 10pt;
}

.tpc-source-title {
  font-size: 14pt;
  display: inline-block;
  margin: 0;
}

.tpc-source-account-title {
  font-weight: 400;
  margin: 0;
  line-height: 1.33;
}

.tpc-source-description {
  color: var(--color-fresh-grey);
  font-weight: 200;
  margin: 0;
  line-height: 1.33;
}

h4.tpc-configure-heading {
  margin-top: 0px;
  /* margin-left: 95px; */
  font-size: 14pt;
}

label.tpc-configure-heading {
  margin-top: 0px;
  /* margin-left: 95px; */
  font-size: 12pt;
}

div.tpc-settings-container {
  /*display: grid;*/
  /*grid-template-columns: 50% 50%;*/
  /*grid-template-rows: auto;*/
  /* margin-left: 95px;
     */
}

div.tpc-new-account {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-right: 7rem;
}
div.tpc-settings-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
}
div.tpc-settings-row {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
div.tpc-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}
/* .tpc-dropdown {
    position: relative;
} */
.tpc-dropdown .tpc-dropdown-menu-wrapper {
  position: relative;
}
.tpc-dropdown .tpc-dropdown-menu {
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  background-color: white;
  border: 2px solid #7f8c9f;
  z-index: 999;
  font-size: 16px;
  line-height: 24px;
  border-radius: 3px;
}
.tpc-dropdown-menu > * {
  padding: 8px 12px;
  cursor: pointer;
}
.tpc-dropdown-menu > *:hover {
  background-color: #eef6fc;
  color: #0063c1;
}

.tpc-dropdown .tpc-dropdown-action {
  color: var(--color-fresh-grey);
  padding: 8px 36px 8px 12px;
  border: 2px solid var(--color-fresh-grey);
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.tpc-dropdown .tpc-dropdown-action img {
  position: absolute;
  right: 9px;
  top: 30%;
  width: 20px;
  opacity: 0.6;
  transform: rotate(180deg);
}

.tpc-dropdown.tpc-dropdown-open .tpc-dropdown-action img {
  transform: rotate(0deg);
}

.tpc-dropdown-capture {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1000px;
  top: 0;
  z-index: 1;
}

div.tpc-actions select {
  color: var(--color-fresh-grey);
}

div.tpc-settings-row label {
  display: inline-block;
  min-width: 20%;
  margin-right: 1rem;
  margin-bottom: 0;
}
div.tpc-settings-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
label.tpc-input {
  line-height: 1;
  padding: 10px 12px;
  font-size: 18pt;
  font-weight: 200;
  color: var(--color-fresh-grey);
  display: inline-block;
}

.tpc-input-caps {
  font-size: 14px;
  font-weight: 200;
  color: var(--color-fresh-grey);
}

/* Borders, shadows, etc. */

.fb-border {
  border-radius: var(--fb-border-radius);
  border-color: var(--fb-border-color);
  border-width: var(--fb-border-width);
  border-style: solid;
}

.fb-shadow {
  box-shadow: var(--fb-shadow-offset) var(--fb-shadow-offset) var(--fb-shadow-color);
}

/* Containers, wrapper */

div#main {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-top: 3rem;
  /*vertical-align: middle;*/
}

div.tpc-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  flex-wrap: wrap;
}

/*div.tpc-branding {*/
/*    !*width: 75px;*!*/
/*    !*height: 75px;*!*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    flex-basis: 150px;*/
/*    flex:1;*/
/*    !*position: relative;*!*/
/*    !*overflow: hidden;*!*/
/*    !*vertical-align: middle;*!*/
/*}*/

div.tpc-copy {
  display: flex;
  flex-direction: column;
  flex-basis: 560px;
  align-self: center;
  letter-spacing: 0.3px;
  text-align: left;
  min-width: 560px;
  /*max-width: 450px;*/
  margin-left: 1.5rem;
  margin-right: 2.5rem;
}

div.tpc-action {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 150px;
  max-width: 150px;
  /* margin-left: 2rem; */
  /* margin-right: 2rem; */
}

div.tpc-configure {
  text-align: left;
  /*overflow: hidden;*/
  /*transition: height 500ms ease-in;*/
  transition:
    max-height 250ms ease-in,
    min-height 250ms ease-in;
  /*animation-name: tcpConfigure;*/
  /*animation-timing-function: ease-in;*/
  /*animation-duration: 250ms;*/
  margin-left: 25px;
}
.tpc-configure-hr {
  width: 720px;
  /* margin-left: 95px; */
}
div.tpc-configure-content {
  margin-left: 40px;
}
.tpc-hr {
  max-width: 940px;
  height: 1px;
  /*border: solid 1px #cdd4d9;*/
}

.tpc-hidden {
  transition:
    max-height 250ms ease-in,
    min-height 250ms ease-in;
  display: none;
}

.tpc-visible {
  transition:
    max-height 250ms ease-in,
    min-height 250ms ease-in;
  min-height: 50px;
}

div.dialogue {
  background: #ffffff;
  margin: auto;
  /* margin-top: 5rem; */
  padding: 2rem;
  text-align: center;
  box-shadow: var(--fb-shadow-offset) var(--fb-shadow-offset) var(--fb-shadow-color);
  border-radius: var(--fb-border-radius);
  border-color: var(--fb-border-color);
  border-width: var(--fb-border-width);
  border-style: solid;
}

div.dialogue.lg {
  width: var(--dialogue-lg-width);
  height: var(--dialogue-lg-height);
  max-width: var(--dialogue-lg-width);
  max-height: var(--dialogue-lg-height);
  overflow: scroll;
}

div.dialogue.md {
  width: var(--dialogue-md-width);
  min-height: var(--dialogue-md-height);
  max-width: var(--dialogue-md-width);
  /*max-height: var(--dialogue-md-height);*/
}

div.dialogue.sm {
  width: var(--dialogue-sm-width);
  /*height: var(--dialogue-sm-height);*/
  max-width: var(--dialogue-sm-width);
  max-height: var(--dialogue-sm-height);
}

div.dialogue.disconnect {
  width: 450px;
  max-width: 450px;
  /*height: 200px;*/
  min-height: 200px;
  padding: 0rem;
  padding-bottom: 1rem;
  text-align: left;
  border-radius: 5px;
}

div.dialogue img.close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.6;
  cursor: pointer;
}

div.dialogue.fs {
  overflow: scroll;
}
.mb-4 {
  margin-bottom: 1.5rem;
}

/* div.dialogue.fs, div.dialogue.await-sync, div.dialogue.sync-complete, div.dialogue.disconnect {
    margin-top: 15%;
} */

div.fs-item {
  border: solid 1px var(--fb-border-color);
  border-bottom: none;
  padding: 0.75em;
  cursor: pointer;
  margin: auto;
}

div.fs-item:hover {
  background: #f0f0f0;
}

div.fs-item.first {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: none;
}

div.fs-item.last {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom: solid 1px var(--fb-border-color);
}

div.fs-item.only {
  border-radius: 5px;
  border-bottom: solid 1px var(--fb-border-color);
}

h3.disconnect {
  font-size: 16pt;
  font-weight: 300;
}

p.disconnect {
  margin: 1rem;
  color: black;
  font-weight: 200;
}

div.disconnect-heading {
  padding: 0.75rem;
  background: #ededed;
}

div.disconnect-action {
  text-align: right;
}

/* Buttons */
button.fb-primary {
  background: var(--color-freshbooks-green);
  /*margin: 2rem;*/
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  border-radius: 5px;
  border: none;
  text-align: center;
  height: 44px;
}
button.start-sync-button {
  margin-left: 1rem;
}
button.fb-primary.outlined {
  background-color: white;
  border: 1px solid var(--color-fresh-green);
  color: var(--color-fresh-green);
}
button.fb-primary.outlined.danger {
  border: 1px solid var(--color-fresh-red);
  color: var(--color-fresh-red);
}
button.fb-primary.disabled {
  opacity: 0.5;
}
button.link {
  background-color: transparent;

  border: none;
  padding: 0;
  font-weight: 400 !important;
  /* color: ; */
}
button.small {
  padding-left: 1.5em;
  padding-right: 1.5em;
  height: 33px;
  font-size: 14px;
}
button.fb-secondary {
  background: var(--color-freshbooks-green);
  color: #ffffff;
  font-size: 10pt;
  font-weight: 200;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 10px;
  border: solid 1px #fcfcfc;
  min-width: 180px;
}

/* button.fb-primary.tpc {

} */

button.fb-cancel {
  background: #ffffff;
  color: var(--color-fresh-grey);
  font-size: 18pt;
  font-weight: 200;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  border-radius: 10px;
  border: none;
}

button.clamped {
  font-size: 14pt;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 1rem;
  text-align: center;
}

input.tpc-input,
select.tpc-input {
  min-width: 185px;
  max-height: 42px;
  background: #ffffff;
  border-radius: 5px;
  border-width: var(--fb-border-width);
  border-color: var(--fb-border-color);
  border-style: solid;
  line-height: 1;
  padding: 10px 12px;
  font-size: 18px;
  /*margin : 1rem;*/
  margin-right: 10px;
}
.tpc-checkbox-input {
  margin: 1rem;
}
.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: #cad3d9;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.sync-tooltip {
  /*margin: 1rem !important;*/
  padding: 15px !important;
  font-size: 14pt !important;
  max-width: 300px;
  border-radius: 5px !important;
  background-color: #062942 !important;
}
.form-switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background: #cad3d9;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  /*box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);*/
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background: var(--color-freshbooks-green);
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}

img.expand-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  /*margin-bottom: 4rem;*/
  margin-right: 1rem;
  transition: transform 250ms ease-in-out;
}

img.expand-toggle.visible {
  transform: rotate(180deg);
}

img.expand-toggle.hidden {
  transform: rotate(90deg);
}

/* Images */
.tpc-coming-soon-image {
  max-width: 70px;
  align-self: center;
}
.marketing-img-brand {
  max-width: 75px;
  max-height: 75px;
  border-radius: 5px;
  border-width: var(--fb-border-width);
  border-color: var(--fb-border-color);
  border-style: solid;
}

.marketing-img-connect {
  max-width: 40px;
  max-height: 40px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.tpc-logo {
  width: 64px;
  height: 64px;
  object-fit: contain;
  /*border-radius: 4px;*/
  /*box-shadow: 2px 2px 0 0 rgba(6, 41, 66, 0.07);*/
  /*border: solid 1px rgba(6, 41, 66, 0.2);*/
}

.fb-logo-full {
  width: 300px;
  height: 96px;
  object-fit: contain;
}

.config-title {
  margin-top: 9px;
}

.config-sub-header {
  font-size: 18px;
  width: 730px;
  display: inline-block;
}

.spinner {
  max-width: 60px;
  animation: Spin 2s linear infinite;
}

.loading-animated {
  animation-name: Pulse;
  animation-timing-function: ease-in-out;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
}

.relative {
  position: relative;
}

/* Settings Wheel */
.settings-container {
  top: 5px;
  left: 5px;
  position: absolute;
  transition: display 500ms;
}
.settings-container .settings-dropdown {
  position: absolute;
  left: 0;
  top: 40px;
  text-align: left;
  padding: 1rem;
  z-index: 2;
}
.settings-btn {
  width: 40px;
  height: 40px;
  background-color: white;
  border: 2px solid #c6ced4;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 1px #c6ced4;
  outline: none;
}
.settings-btn:focus {
  outline: none;
}
.settings-switch-business {
  width: 100%;
  background-color: white;
  border: solid 0.5px #cdd4d9;
  border-radius: 10px;
  padding: 0.5rem;
  color: var(--color-fresh-grey);
  transition:
    color 200ms,
    border-color 200ms;
}
.settings-switch-business:hover {
  border-color: var(--color-freshbooks-blue);
  color: var(--color-freshbooks-blue);
}
.settings-dropdown-capture {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}
/* MODAL POPUP */
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #1212127f;
}
.popup-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sync-stats {
  font-weight: 500;
  letter-spacing: 0.05em;
}

@keyframes Pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.15);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes Spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@media only screen and (max-width: 1042px) {
  div.dialogue.lg {
    max-width: 95% !important;
  }
}

.alert-danger {
  display: inline-block;
  max-width: 940px;
  color: #000 !important;
  text-align: left;
}

.error_icon {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  border-radius: 15px;
  background-color: #fff;
  padding: 3px;
}
